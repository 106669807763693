import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space, Tag, Switch, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'

import { setFileInfo, setFileUploaded, setFileUploading, setFormSaveInfo, setFormTags, setFormTagsColor, setUploadMode, setEdit } from '../store/modules/upload';


const { Option } = Select;

const DrawerComp = () => {
    const [open, setOpen] = useState(false)
    const { fileInfo } = useSelector(state => state.upload)
    const { isExist } = useSelector(state => state.upload)
    const { fileUploaded } = useSelector(state => state.upload)
    const { exInfo } = useSelector(state => state.upload)
    const { uploadMode } = useSelector(state => state.upload)
    const { edit } = useSelector(state => state.upload)
    const dispatch = useDispatch()
    const [inputValue, setInputValue] = useState('')
    const [optionsValue, setOptionsValue] = useState('#5BD8A6')
    const [tags, setTags] = useState([])
    const [tagsColor, setTagsColor] = useState([])
    const [form] = Form.useForm()

    const defaultDate = dayjs()

    const showDrawer = () => {
        form.resetFields()
        setOpen(true);
        if (exInfo.tags) {
            setTags(exInfo.tags.split(','))
            setTagsColor(exInfo.tags_color.split(','))
        }
    };

    const submit = () => {
        const values = form.getFieldsValue()
        form.validateFields().then(() => {
            form.resetFields()
            dispatch(setFileInfo({}))
            dispatch(setFileUploaded(false))
            dispatch(setFileUploading(true))
            dispatch(setFormSaveInfo(values))
            dispatch(setFormTags(tags))
            dispatch(setFormTagsColor(tagsColor))
            dispatch(setEdit(true))
            setOpen(false)
        }).catch(() => {
            return false
        });
    };

    const onClose = () => {
        setOpen(false)
        setTags([])
        dispatch(setUploadMode(false))
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleInputConfirm = () => {
        const newValue = inputValue.replace(/[^\u4E00-\u9FA5]/g, '').trim()
        if (newValue && !tags.includes(newValue) && tags.length < 10) {
            setTags([...tags, newValue])
            setTagsColor([...tagsColor, optionsValue])
        }
        setInputValue('');
    }

    const handleTagClose = (removedTag) => {
        let index = tags.indexOf(removedTag)
        const newTags = tags.toSpliced(index, 1)
        const newTagsColor = tagsColor.toSpliced(index, 1)
        setTags(newTags)
        setTagsColor(newTagsColor)
    }

    const options = [
        {
            value: '#4096ff',
            label: '好',
        },
        {
            value: '#5BD8A6',
            label: '中',
        },
        {
            value: '#fa541c',
            label: '差',
        },
    ]

    const handleSelectChange = (value) => {
        setOptionsValue(value)
    }

    const renderArr = tags.map((tag, index) => (
        <span key={tag} style={{ display: 'inline-block' }}>
            <Tag
                closable
                onClose={() => handleTagClose(tag)}
                color={tagsColor[index]}
            >
                {tag}
            </Tag>
        </span>
    ))

    return (
        <>
            {fileUploaded && (
                <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />} style={{ marginTop: 2 }}>
                    开始存档
                </Button>
            )}
            <Drawer
                title={`${isExist ? '更新存档' : '新增存档'}`}
                width={720}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                loading
                extra={
                    <Space>
                        <Switch checkedChildren="共存" unCheckedChildren="替换" onChange={(checked) => { dispatch(setUploadMode(checked)) }} disabled={!isExist} checked={ uploadMode === 'coexist' ? true : false }></Switch>
                        <Button onClick={() => { setOpen(false) }}>取消</Button>
                        <Button onClick={submit} type="primary">
                            提交
                        </Button>
                    </Space>
                }
                footer={
                    <Checkbox style={{ textAlign: 'left' }} onChange={(e) => { dispatch(setEdit(!e.target.checked)) }} disabled={!isExist} checked={!edit} >编辑</Checkbox>
                }
            >
                <Form
                    form={form}
                    initialValues={{
                        gameENName: isExist ? fileUploaded ? exInfo.game_en_name : '' : fileUploaded ? fileInfo.saveInfo.gameENName : '',
                        gameCNName: isExist ? fileUploaded ? exInfo.game_cn_name ? exInfo.game_cn_name : '' : '' : '',
                        versions: fileUploaded ? fileInfo.saveInfo.versions : '',
                        webUrl: isExist ? fileUploaded ? exInfo.web_url : '' : '',
                        type: isExist ? fileUploaded ? exInfo.type : '' : fileUploaded ? fileInfo.saveInfo.type : '',
                        priority: isExist ? fileUploaded ? exInfo.priority : '' : '',
                        lastPlayTime: defaultDate,
                        description: isExist ? fileUploaded ? exInfo.game_description : '' : '',
                    }}
                    layout="vertical">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="gameENName"
                                label="游戏英文名"
                                rules={[
                                    { required: true, message: '请输入游戏名称', },
                                    { pattern: /^[A-Z][a-zA-Z0-9]*$/, message: '只允许输入字母或数字，不允许输入特殊字符' }
                                ]}
                            >
                                <Input
                                    disabled={isExist && edit}
                                    placeholder="请输入游戏名称"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="gameCNName"
                                label="游戏中文名"
                                rules={[
                                    { pattern: /^[\u4e00-\u9fa5\d]+$/, message: '只允许输入汉字或数字，不允许输入特殊字符' }
                                ]}
                            >
                                <Input disabled={exInfo.game_cn_name && edit} placeholder="请输入游戏名称（选填）" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="webUrl"
                                label="官网链接"
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入游戏官网链接"
                                    disabled={exInfo.web_url && edit}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="type"
                                label="类型"
                                rules={[{ required: true, message: '请输入游戏类型' }]}
                            >
                                <Select placeholder="请输入游戏类型" disabled={exInfo.type && edit}>
                                    <Option value="SLG">SLG</Option>
                                    <Option value="RPG">RPG</Option>
                                    <Option value="Other">其他</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="versions"
                                label="游戏版本"
                                rules={[
                                    { required: true, message: '请输入游戏版本号' },
                                    { pattern: /^\d+\.\d+\.\d+$/, message: '请输入标准的版本号格式，例如：1.0.0' }

                                ]}
                            >
                                <Input placeholder={`${isExist ? `上一次存档时的游戏版本号：${exInfo.latest_version}` : '请输入游戏版本号'}`} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="isFinish"
                                label="是否完成"
                                rules={[{ required: true, message: '请选择是否打完了该版本' }]}
                            >
                                <Select placeholder="请选择是否打完了该版本">
                                    <Option value="yes">是</Option>
                                    <Option value="no">否</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="priority"
                                label="优先级"
                                rules={[{ required: true, message: '请输入优先级' }]}
                            >
                                <Select placeholder="请输入优先级">
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="lastPlayTime"
                                label="最后游玩时间"
                                rules={[{ required: true, message: '请输入最后游玩时间' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="tags"
                                label="标签"
                            >
                                <div>
                                    <Space.Compact block>
                                        <Select
                                            options={options}
                                            style={{ width: '20%' }}
                                            value={optionsValue}
                                            onChange={handleSelectChange}
                                        />
                                        <Input
                                            type="text"
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            onPressEnter={handleInputConfirm}
                                            maxLength={5}
                                            placeholder="输入内容，回车添加（长度为5，数量为9）"
                                            style={{ width: '50%' }}
                                        />
                                    </Space.Compact>
                                    <div style={{ marginTop: 16 }}>
                                        {renderArr}
                                    </div>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name='description'
                                label='描述'
                            >
                                <Input.TextArea showCount maxLength={100} rows={4} placeholder='请输入游戏描述' style={{ height: 120, resize: 'none' }} disabled={exInfo.game_description && edit} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name='versionDescription'
                                label='版本描述'
                            >
                                <Input.TextArea showCount maxLength={100} rows={4} placeholder='请输入版本描述' style={{ height: 120, resize: 'none' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};

export default DrawerComp;

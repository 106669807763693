import { createSlice } from "@reduxjs/toolkit"

const webStore = createSlice({
    name: "user",
    initialState: {
        modalOpen: false
    },
    reducers: {
        setModalOpen(state, action) {
            state.modalOpen = action.payload
        }
    }
})

const { setModalOpen } = webStore.actions

export { setModalOpen }

const reducer = webStore.reducer

export default reducer
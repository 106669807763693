import { createSlice } from "@reduxjs/toolkit"

import { request, setToken, getToken, removeToken } from "../../utils"

const userStore = createSlice({
    name: "user",
    initialState: {
        userInfo: getToken('user_info', true) || {},
        tokenExpiresTime: getToken('token_expires_time') || 60000
    },
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        setSpecialIdArr: (state, action) => {
            state.specialIdArr = action.payload
        },
        setSpecialId: (state, action) => {
            state.specialId = action.payload
        },
        setTokenExpiresTime: (state, action) => {
            state.tokenExpiresTime = action.payload
        },
        loginOut: (state) => {
            state.userInfo = {}
            window.location.href = '/login'
            removeToken('all')
        }
    }
})

//----------------------------------------------------------------------------------------------------------

//异步请求部分↓↓↓

//----------------------------------------------------------------------------------------------------------

const userRegister = ({ email, username, password, authkey }) => {
    return async () => {
        const formData = new URLSearchParams({ email, username, password, authkey });
        const res = await request.post('/cloudsave/api/user/register', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        return res
    }
}

const userLogin = ({ username, password }) => {
    return async (dispatch) => {
        const formData = new URLSearchParams({ username, password });
        const res = await request.post('/cloudsave/api/user/login', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if (res.status === 200) {
            dispatch(setUserInfo(res.data.userInfo))
            setToken('', res.data.accessToken)
            setToken('refresh_token', res.data.refreshToken)
            setToken('user_info', res.data.userInfo, true)
            setToken('token_expires_time', res.data.tokenExpiresTime)
            dispatch(setTokenExpiresTime(res.data.tokenExpiresTime))
            return res
        } else {
            return res
        }
    }
}

const refreshToken = () => {
    return async (dispatch) => {
        const accessToken = getToken()
        const refreshToken = getToken('refresh_token')
        const formData = new URLSearchParams({ refreshToken, accessToken });
        const res = await request.post('/cloudsave/api/user/refresh-token', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        if (res.status === 200) {
            setToken('', res.data.token)
            return res.data.token
        } else {
            return res
        }
    }
}

const getAllSpecialId = () => {
    return async () => {
        const res = await request.get('/cloudsave/api/onedrive/get-all-specialid', {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        })

        if (res.status === 200) {
            setToken('special_id_arr', res.data.specialIdArr, true)
            return res
        } else {
            return res
        }
    }
}


const refreshAzureToken = (specialId, ifreturntoken) => {
    return async () => {
        const formData = new URLSearchParams({ specialId, ifreturntoken })
        const res = await request.post('/cloudsave/api/onedrive/refresh-token', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken()
            }
        })
        if (res.data.authUrl) {
            return res.data.authUrl
        }
        setToken('azure_token', res.data.token)
        setToken('special_id', specialId)

        return res
    }
}

const addUserFolderId = (userFolderId, username) => {
    return async (dispatch) => {
        const formData = new URLSearchParams({ userFolderId, username });
        const res = await request.post('/cloudsave/api/user/add-user-folder-id', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken()
            }
        })

        if (res.status === 200) {
            dispatch(setUserInfo(res.data.userInfo))
            setToken('user_info', res.data.userInfo, true)
            return res
        } else {
            return res
        }
    }
}

//导出

const { setUserInfo, setTokenExpiresTime, loginOut } = userStore.actions

export { userRegister, userLogin, refreshToken, getAllSpecialId, refreshAzureToken, addUserFolderId, loginOut }

const reducer = userStore.reducer

export default reducer
import { Button } from "antd"

const Inodex = () => {
  return (
    <div className="cloud-saves-index">
      <div className="cloud-saves-index-mask">
        <div className="cloud-saves-index-content">
          <h1 className="cloud-saves-index-title">cloud saves</h1>
          <h3 className="cloud-saves-index-subtitle">个人云存档服务</h3>
          <Button ghost className="cloud-saves-index-button" onClick={() => window.location.href = "/login"}>登录账号</Button>
          <Button ghost className="cloud-saves-index-button" onClick={() => window.location.href = "/layout"}>管理库存</Button>
        </div>
      </div>
    </div>
  )
}

export default Inodex
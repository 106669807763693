import { Popconfirm, message } from 'antd';
import { Link } from 'react-router-dom';
import { EditableProTable } from '@ant-design/pro-components';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';

import { request, getToken } from '../utils';

import { compareVersion } from '../utils/compareVersion';

const List = () => {
    const editorFormRef = useRef()
    const { gameList } = useSelector((state) => state.list);
    const { index } = useSelector((state) => state.list);
    const [loading, setLoading] = useState(true)

    const columns = [
        {
            title: '存档id',
            dataIndex: 'save_file_id',
            key: 'save_file_id',
            sorter: (a, b) => a.save_file_id - b.save_file_id,
        },
        {
            title: '版本',
            key: 'versions',
            dataIndex: 'versions',
            sorter: (a, b) => compareVersion(a.versions, b.versions, 'list'),
        },
        {
            title: '版本描述',
            dataIndex: 'version_description',
            key: 'version_description',
            ellipsis: true,
        },
        {
            title: '上传时间',
            dataIndex: 'submission_time',
            key: 'submission_time',
            valueType: 'date',
            sorter: (a, b) => dayjs(a.submission_time) - dayjs(b.submission_time),
        },
        {
            title: '大小',
            dataIndex: 'size_show',
            key: 'size_show',
            sorter: (a, b) => a.size - b.size,
        },
        {
            title: '优先级变化',
            dataIndex: 'priority_change',
            key: 'priority_change',
        },
        {
            title: '操作',
            width: 200,
            key: `option-${Math.random()}`,
            render: (text, record, _, action) => [
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                    key={`downlod-${record.save_file_id}`}
                    onClick={() => {
                        //异步下载请求
                        axios.get(`https://graph.microsoft.com/v1.0/me/drive/items/${record.file_id}`, {
                            headers: {
                                Authorization: `Bearer ` + getToken('azure_token'),
                            },
                        })
                            .then((response) => {
                                const downloadUrl = response.data['@microsoft.graph.downloadUrl']
                                window.open(downloadUrl)  // 使用重定向的URL进行下载
                            })
                            .catch(() => {
                                message.error('下载失败，请检查网络连接')
                            })
                    }}
                    style={{
                        marginRight: 12,
                    }}
                >
                    下载
                </Link>,
                <Popconfirm
                    key={`Popconfirm-{record.save_file_id}`}
                    title="确认删除？"
                    description="你确定要删除此存档吗?"
                    onConfirm={() => {
                        //异步删除请求
                        request.delete(`/cloudsave/api/onedrive/delete-save-file?game_id=${record.game_id}&file_id=${record.file_id}&azure_token=${getToken('azure_token')}`, {
                            headers: {
                                Authorization: 'Bearer ' + getToken()
                            }
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    editorFormRef.current.resetFields()
                                    message.success(res.data.message);
                                }
                            })
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Link
                        key={`delete-${record.save_file_id}`}
                    >
                        删除
                    </Link>
                </Popconfirm>,
            ],
        },
    ];

    return (
        <div>
            <EditableProTable
                rowKey="save_file_id"
                scroll={{
                    x: 960,
                }}
                editableFormRef={editorFormRef}
                maxLength={5}
                name="table"
                recordCreatorProps={false}
                loading={loading}
                request={async (params) => {
                    const res = await request.get(`/cloudsave/api/save-info/get-all-save-file-info?id=${gameList[index].id}`, {
                        headers: {
                            Authorization: 'Bearer ' + getToken()
                        }
                    })

                    res.data.data.map((item) => {
                        return item.size_show = (item.size / (1024 * 1024)).toFixed(2) + 'MB'
                    })

                    setLoading(false)

                    return {
                        data: res.data.data,
                        success: true,
                        total: res.data.total,
                    }
                }}
                columns={columns}
            />
        </div>
    );
};

export default List;
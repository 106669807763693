import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./modules/user.js"
import uploadReducer from "./modules/upload.js"
import listReducer from "./modules/list.js"
import webReducer from "./modules/web.js"


const store = configureStore({
    reducer: {
        user : userReducer,
        upload : uploadReducer,
        list: listReducer,
        web: webReducer
    }
})

export default store
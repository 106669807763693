import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UploadOutlined, AppstoreOutlined, UserOutlined } from '@ant-design/icons'
import { ConfigProvider, Menu } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import { refreshToken, refreshAzureToken, loginOut } from '../../store/modules/user.js'
import { getToken } from '../../utils/token.js';

import MyModal from '../../components/Modal.jsx'

const Layout = () => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [url, setUrl] = useState('')
  const { userInfo } = useSelector(state => state.user)
  const { tokenExpiresTime } = useSelector(state => state.user)
  const { fileUploading } = useSelector(state => state.upload)

  const navigate = useNavigate()

  const items = [
    {
      label: '上传',
      key: 'layout',
      icon: <UploadOutlined />,
    },
    {
      label: '库存',
      key: 'inventory',
      icon: <AppstoreOutlined />,
    },
    {
      label: userInfo.username ? userInfo.username : '请登录',
      key: 'username',
      icon: <UserOutlined />,
      children: [
        {
          label: '账户明细',
          key: 'account-details',
        },
        {
          label: '退出登录',
          key: 'loginOut',
          style: {
            color: 'red',
          },
        },
      ],
    },
  ]


  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(refreshToken())
    }, tokenExpiresTime / 10)

    return () => clearInterval(timer)
  }, [dispatch, tokenExpiresTime])

  useEffect(() => {
    const specialIdArr = getToken('special_id_arr', true)
    let specialId = ''
    if (specialIdArr) {
      specialId = specialIdArr[0].special_id
    } else {
      specialId = getToken('special_id')
    }
    dispatch(refreshAzureToken(specialId, true))
      .then(res => {
        if (typeof res === 'string') {
          setIsModalOpen(true)
          setUrl(res)
        }
      })
  }, [dispatch])

  const onClick = (e) => {
    const key = e.key

    // 执行路由跳转
    if (key === 'layout') {
      navigate('/layout')
    } else if (key === 'inventory') {
      navigate('/layout/inventory')
    } else if (key === 'account-details') {
      navigate('/layout/account-details')
    } else if (key === 'loginOut') {
      dispatch(loginOut())
    }
  }

  const onOk = () => {
    window.location.href = url
    setIsModalOpen(false)
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className='Layout'>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemHeight: 30,
              dropdownWidth: 96,
              itemPaddingInline: 30
            },
          },
        }}
      >
        <Menu className='Menu' onClick={onClick} selectedKeys={window.location.pathname.replace(/^.*\//, '')} mode="horizontal" items={items} disabled={fileUploading} />
      </ConfigProvider>
      <div className='Layout-content'>
        <Outlet />
      </div>
      <MyModal
        title={'azure'}
        isModalOpen={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        content={'需要进行azure登录操作，请点击下方按钮进行登录。'}
        closable={false}
        maskClosable={false}
        keyboard={false}
      />
    </div>
  )
}

export default Layout

const TOKENKEY = 'token_key'
function setToken(tokenName, token, json) {
    if (tokenName) {
        if (json) {
            localStorage.setItem(tokenName, window.btoa(window.encodeURIComponent(JSON.stringify(token))))
        } else {
            localStorage.setItem(tokenName, token)
        }
    } else {
        localStorage.setItem(TOKENKEY, token)
    }
}

function getToken(keyName, json) {
    if (keyName) {
        if (json) {
            const data = localStorage.getItem(keyName)
            if (data) {
                return JSON.parse(decodeURIComponent(window.atob(data)))
            } else {
                return null
            }
        } else {
            return localStorage.getItem(keyName)
        }
    }
    return localStorage.getItem(TOKENKEY)
}

function removeToken(type) {
    if (type === 'all') {
        localStorage.clear();
    } else {
        localStorage.removeItem(TOKENKEY)
    }
}

export {
    setToken,
    getToken,
    removeToken
}
// src/components/TermsOfServicePage.js
import React from 'react';
import { Card, Typography, Layout, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const TermsOfServicePage = () => {
  const handleBack = () => {
    window.history.back();
  };

  return (
    <Layout style={{ minHeight: '100vh', padding: '24px' }}>
      <Content style={{ maxWidth: '800px', margin: 'auto' }}>
        <Card bordered={false} style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}>
          <Button 
            type="text" 
            onClick={handleBack} 
            icon={<ArrowLeftOutlined />} 
            style={{
              borderRadius: '50%',
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '16px',
              border: '1px solid #d9d9d9',
              background: 'transparent'
            }}
          />
          <Typography>
            <Title level={2} style={{ textAlign: 'center', marginBottom: '16px' }}>服务条款</Title>
            <div style={{ marginBottom: '8px' }}>
              <Title level={4}>1. 网站简介</Title>
              <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
                Cloud Saves是一家私人网站，主要提供个人云存档服务。通过本网站，用户可以将自己的存档文件上传至云端进行保存和管理。
              </Paragraph>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Title level={4}>2. 用户注册</Title>
              <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
                所有用户必须通过管理员的审核才能注册。我们有权根据审核结果决定是否批准用户的注册申请。
              </Paragraph>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Title level={4}>3. 账号及存档文件的所有权</Title>
              <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
                用户在Cloud Saves注册的账号及其上传的所有存档文件均归网站所有。用户仅拥有这些账号和存档文件的使用权，不享有所有权。
              </Paragraph>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Title level={4}>4. 用户义务</Title>
              <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
                用户在使用本网站服务时，应遵守相关法律法规，不得利用本网站进行任何非法活动。用户应妥善保管账号信息，防止账号被盗用。
              </Paragraph>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Title level={4}>5. 服务变更与终止</Title>
              <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
                Cloud Saves有权根据需要随时对服务内容进行变更或终止，变更或终止的决定将通过网站公告或其他适当方式通知用户。
              </Paragraph>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Title level={4}>6. 隐私政策</Title>
              <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
                我们重视用户的隐私保护，将严格按照隐私政策收集、使用、存储和分享用户信息。具体内容请参阅我们的隐私政策。
              </Paragraph>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Title level={4}>7. 免责声明</Title>
              <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
                本网站不对因不可抗力或非本网站原因导致的服务中断或信息丢失等情况承担责任。我们将尽力确保服务的稳定和安全，但不保证服务的绝对可靠性。
              </Paragraph>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Title level={4}>8. 适用法律</Title>
              <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
                本服务条款受网站服务器所在的国家或地区的法律管辖和解释。
              </Paragraph>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Title level={4}>9. 条款的修改</Title>
              <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
                Cloud Saves保留随时修改这些服务条款的权利。修改后的条款将在网站上公布，用户继续使用本网站即表示接受修改后的条款。
              </Paragraph>
            </div>
            <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>
              如有任何疑问或需进一步信息，请通过管理员联系邮箱联系我们。
            </Paragraph>
            <Paragraph style={{ textIndent: '2em', marginBottom: '8px' }}>感谢您使用Cloud Saves！</Paragraph>
            <Paragraph style={{ textIndent: '2em' }}>Cloud Saves团队</Paragraph>
          </Typography>
          <Button 
            type="text" 
            onClick={handleBack} 
            icon={<ArrowLeftOutlined />} 
            style={{
              borderRadius: '50%',
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '16px',
              border: '1px solid #d9d9d9',
              background: 'transparent'
            }}
          />
        </Card>
      </Content>
    </Layout>
  );
};

export default TermsOfServicePage;

import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { userRegister } from '../../store/modules/user.js'


const Register = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onFinish = (values) => {
        if (!values.agreement) {
            message.error('请同意服务条款。');
            return;
        }

        const { email, username, password, authkey } = values;
        dispatch(userRegister({ email, username, password, authkey }))
            .then((res) => {
                if (res.status === 201) {
                    message.success(res.data.message)
                    navigate('/login')
                } else {
                    message.error(res.data.message)
                }
            })
    };

    return (
        <div className='cloud-saves-register'>
            <div className='cloud-saves-register-mask'>
                <div className='cloud-saves-register-content'>
                    <h1 className='cloud-saves-register-title'>云存档注册</h1>
                    <Form
                        name="register"
                        initialValues={{ agreement: false }}
                        onFinish={onFinish}
                    >

                        <Form.Item
                            label="邮　箱"
                            name="email"
                            rules={[
                                { required: true, message: '请输入邮箱!' },
                                { type: 'email', message: '请输入有效的邮箱地址!' }]}
                        >
                            <Input type="email" />
                        </Form.Item>

                        <Form.Item
                            label="用户名"
                            name="username"
                            rules={[
                                { required: true, message: '请输入用户名!' },
                                { pattern: /^[a-zA-Z][a-zA-Z0-9]{2,8}$/, message: '用户名必须以字母开头，长度为3到9位!' }
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="密　码"
                            name="password"
                            rules={[
                                { required: true, message: '请输入密码!' },
                                { min: 6, max: 30, message: '密码长度必须为6到30位!' },
                                { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/, message: '密码必须包含大小写字母、数字和特殊字符!' }
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="鉴权码"
                            name="authkey"
                            rules={[{ required: true, message: '请输入鉴权码!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item name="agreement" valuePropName="checked">
                            <Checkbox>我已阅读并同意<a href="/terms">服务条款</a></Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                注册
                            </Button>
                            <Link to="/login">
                                <Button type="link">返回登录</Button>
                            </Link>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Register;

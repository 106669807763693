function saveNameMatch(saveName) {
    // 正则表达式
    const regex = /\.rar$|\.zip$|\.7z$/

    saveName = saveName.replace(regex, '')

    const pattern = /([A-Za-z]+)-([\d.]+)/;

    // 执行匹配
    const matches = saveName.match(pattern);

    // 如果匹配成功
    if (matches) {
        if (matches[0] && matches[1]) {
            const gameName = matches[1];
            const versions = matches[2];
            return { gameName, versions };
        }
    }

    const arr1 = saveName.split(' ');
    if (arr1.length >= 2) {
        const gameName = arr1[0];
        let versions = ''
        if (arr1[1][0] === 'v') {
            versions = arr1[1].slice(1)
        } else {
            versions = arr1[1]

        }
        return { gameName, versions };
    }

    const arr2 = saveName.split('-')
    if (arr2.length >= 2) {
        const gameName = arr2[0];
        let versions = ''
        if (arr2[1][0] === 'v') {
            versions = arr2[1].slice(1)
        } else {
            versions = arr2[1]

        }
        return { gameName, versions };
    }

    return null;

}

export {
    saveNameMatch
}
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProList } from '@ant-design/pro-components';
import { Tag, message } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import axios from 'axios';

import MyInventoryModal from '../../../components/MyInventoryModal'

import { setModalOpen } from '../../../store/modules/web';
import { setIndex } from '../../../store/modules/list';
import { setGameList } from '../../../store/modules/list'

import { request } from '../../../utils/index'
import { getToken } from '../../../utils';

const Inventory = () => {
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useState({})
    const { modalOpen } = useSelector((state) => state.web)

    const dispatch = useDispatch()

    const dayCount = (time) => {
        let startDate = dayjs(time);

        let currentDate = dayjs();

        let timeDifference = currentDate - startDate;
        
        if (timeDifference / (1000 * 60 * 60 * 24) < 1) {
            return 0
        }

        return Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    }
    const imgSrc = (row) => {
        if (row.img_url === 'https://s3.bmp.ovh/imgs/2024/05/19/ce314c38b3038176.png') {
            return 'https://s3.bmp.ovh/imgs/2024/05/19/ce314c38b3038176.png'
        } else {
            const url = new URL(row.img_url)
            const tokenTime = url.searchParams.get("token-time")
            if (tokenTime) {
                if (dayjs().unix() >= tokenTime) {
                    axios.get(`https://patreon-tool.dmfy.fun/get-images-url?url=${row.web_url}`)
                        .then((res) => {
                            const { id } = row
                            const img_url = res.data.imgUrl
                            const formData = new URLSearchParams({ id, img_url })
                            request.post('/cloudsave/api/save-info/update-save-info-img-url', formData, {
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    'Authorization': 'Bearer ' + getToken()
                                }
                            })
                                .then((res) => {
                                    message.success(res.data.message)
                                })
                        })
                }
            }
            return `https://patreon-tool.dmfy.fun/get-images?url=${encodeURIComponent(row.img_url)}`
        }
    }

    return (
        <div className='inventory-list'>
            <ProList
                pagination={{
                    defaultPageSize: 8,
                    showSizeChanger: false,
                }}
                search={{
                    filterType: 'light',
                    placeholder: '搜索游戏名',
                    labelWidth: 0,
                }}
                onFilter={true}
                onSubmit={(params, a) => {
                    setParams(params)
                }}
                grid={{ gutter: 16, column: 2 }}
                onItem={(row, index) => {
                    return {
                        onClick: () => {
                            dispatch(setModalOpen(true))
                            dispatch(setIndex(index))
                        },
                    };
                }}
                metas={{
                    title: {
                        title: '游戏名',
                        dataIndex: 'game_en_name',
                    },
                    subTitle: {
                        render: (_, row) => {
                            return (
                                <div>
                                    <Tag className='inventory-list-tag' color={row.tags_color[0]}>{row.tags[0]}</Tag>
                                    <Tag className='inventory-list-tag' color={row.tags_color[1]}>{row.tags[1]}</Tag>
                                    <Tag className='inventory-list-tag' color={row.tags_color[2]}>{row.tags[2]}</Tag>
                                </div>
                            )
                        },
                        search: false
                    },
                    actions: {
                        render: (_, row) => {
                            return (
                                <p className='actions-p' style={{ margin: 0, color: 'rgba(0, 0, 0, 0.88)', fontSize: '14px', fontWeight: 500 }}>{row.latest_versions}</p>
                            )
                        }
                    },
                    avatar: {
                        render: () => {
                            return (
                                <CloudDownloadOutlined style={{ fontSize: '16px' }} />
                            )
                        },
                        search: false
                    },
                    content: {
                        render: (_, row) => {
                            return (
                                <div
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '90%',
                                            margin: '0 auto',
                                            marginBottom: 5,
                                        }}
                                    >
                                        <img src={`${imgSrc(row)}`} alt='img' width={'100%'} style={{ aspectRatio: '4/1', objectFit: 'cover' }} />
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p className='actions-p-1'>{row.priority}</p>
                                            <p></p>
                                            <p className='actions-p-2'>{dayCount(row.last_play_time_count)}天前</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        },
                        search: false
                    },
                    type: {
                        title: '类型',
                        valueType: 'select',
                        valueEnum: {
                            SLG: {
                                text: 'SLG',
                            },
                            RPG: {
                                text: 'RPG',
                            },
                            Other: {
                                text: '其他',
                            },
                        }
                    },
                    isfinish: {
                        title: '是否完成',
                        valueType: 'select',
                        valueEnum: {
                            是: {
                                text: '是',
                            },
                            否: {
                                text: '否',
                            },
                        }
                    },
                    priority: {
                        title: '优先级',
                        valueType: 'select',
                        valueEnum: {
                            1: {
                                text: '1',
                            },
                            2: {
                                text: '2',
                            },
                            3: {
                                text: '3',
                            },
                        },
                    },
                    time: {
                        title: '距今',
                        dataIndex: 'time',
                        valueType: 'digit',
                    },
                    tags: {
                        title: '标签',
                        dataIndex: 'tags',
                    }
                }}
                headerTitle="存档信息列表"
                loading={loading}
                request={async (params) => {
                    try {
                        if (params.isfinish === '是') {
                            params.isfinish = 1
                        } else if (params.isfinish === '否') {
                            params.isfinish = 0
                        }
                        if (params.time) {
                            if (typeof params.time === 'string' && !parseFloat(params.time)) {
                                message.error('请输入数字')
                                return {
                                    data: [],
                                    success: true,
                                    total: 0,
                                }
                            }
                        }
                        const res = await request.get(`/cloudsave/api/save-info/get-all-save-info`, {
                            params: params,
                            headers: {
                                Authorization: 'Bearer ' + getToken()
                            }
                        })

                        res.data.saveInfo.forEach(item => {
                            if (item.tags) {
                                item.tags = item.tags.split(',')
                                item.tags_color = item.tags_color.split(',')
                            }
                            item.last_play_time_count = item.last_play_time
                            item.last_play_time = dayjs(item.last_play_time).format("YYYY-MM-DD")
                        })

                        dispatch(setGameList(res.data.saveInfo))
                        setLoading(false)

                        return {
                            data: res.data.saveInfo,
                            success: true,
                            total: res.data.saveInfo.length,
                        }
                    }
                    catch {
                        message.error('获取存档信息失败')
                    }
                }
                }
                params={params}
            />
            {modalOpen && (
                <MyInventoryModal open={modalOpen} ></MyInventoryModal>
            )}
        </div>
    );
}

export default Inventory

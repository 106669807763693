import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { } from '@ant-design/icons';
import {
  ModalForm,
  ProDescriptions
} from '@ant-design/pro-components';
import { Form, Tag, Button, Popconfirm, message } from 'antd';

import { setModalOpen } from '../store/modules/web';

import List from './List';

import { request, getToken } from '../utils';

const MyInventoryModal = ({ open }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { gameList } = useSelector((state) => state.list);
  const { index } = useSelector((state) => state.list);
  const { deletePattern } = useSelector(state => state.list)
  const [loading, setLoading] = useState(false)

  let renderArr = []

  const dayCount = (time) => {
    let startDate = new Date(time);

    let currentDate = new Date();

    let timeDifference = currentDate.getTime() - startDate.getTime();

    return Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  }

  const data = JSON.parse(JSON.stringify(gameList[index]))

  const {
    game_en_name,
    game_cn_name,
    tags,
    tags_color,
  } = data

  if (!data.game_description) {
    data.game_description = '暂无描述'
  }

  if (data.isfinish) {
    data.isfinish = '是'
  } else {
    data.isfinish = '否'
  }

  const columns = [
    {
      label: "游戏描述",
      dataIndex: "game_description",
      span: 2,
      valueType: "text",
      contentStyle: { maxWidth: '80%' },
      ellipsis: true,
    },
    {
      label: "游戏类型",
      dataIndex: "type",
      valueType: "text"
    },
    {
      label: "优先级",
      dataIndex: "priority",
      tooltip: data.latest_priority_change,
      valueType: "digit"
    },
    {
      label: "官网链接",
      tooltip: '仅供参考，以实际为准',
      dataIndex: "web_url",
      valueType: "text",
      ellipsis: true,
      copyable: true,
    },
    {
      label: "最新版本",
      tooltip: "云存档最新上传的版本，非游戏最新版本",
      dataIndex: "latest_versions",
    },
    {
      label: "是否完成",
      dataIndex: "isfinish",
    },
    {
      label: "上次游玩时间",
      tooltip: `${dayCount(data.last_play_time)}天前`,
      dataIndex: "last_play_time",
      valueType: "text",
      ellipsis: true,
    },
  ];

  if (tags.length > 0) {
    renderArr = tags.map((tag, index) => (
      <span key={tag} style={{ display: 'inline-block' }}>
        <Tag
          color={tags_color[index]}
        >
          {tag}
        </Tag>
      </span>
    ))
  }

  return (
    <ModalForm
      title='&nbsp;'
      open={open}
      form={form}
      modalProps={{
        destroyOnClose: true,
        onCancel: () => dispatch(setModalOpen(false)),
      }}
      submitter={{
        render: (props, defaultDoms) => {
          if (deletePattern) {
            return [
              <Button
                key="ok"
                onClick={() => {
                  dispatch(setModalOpen(false))
                }}
              >
                取消
              </Button>,
              <Popconfirm
                key={'popconfirm'}
                title="确认删除？"
                description="你确定要删除此存档库吗?"
                onConfirm={() => {
                  //异步删除请求
                  setLoading(true)
                  request.delete(`/cloudsave/api/onedrive/delete-save-info?game_id=${data.id}&azure_token=${getToken('azure_token')}`, {
                    headers: {
                      'Authorization': `Bearer ` + getToken()
                    }
                  })
                    .then(res => {
                      if (res.status === 200) {
                        setLoading(false)
                        message.success('删除成功！')
                        dispatch(setModalOpen(false))
                      }
                    })
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  key="delete"
                  loading={loading}
                  danger
                >
                  删除
                </Button>
              </Popconfirm>
            ];
          } else {
            return [
              <Button
                key="ok"
                onClick={() => {
                  dispatch(setModalOpen(false))
                }}
              >
                取消
              </Button>
            ];
          }
        },
      }}
    >
      <ProDescriptions
        columns={columns}
        column={2}
        title={`${game_en_name} | ${game_cn_name ? game_cn_name : '暂无'}`}
        tooltip={`英文名：${game_en_name}，中文名：${game_cn_name ? game_cn_name : '暂无'}。`}
        request={async () => {
          return {
            data: data,
            success: true,
          }
        }}
      >
        <ProDescriptions.Item
          editable={false}
        >
          {renderArr}
        </ProDescriptions.Item>
      </ProDescriptions>
      <List></List>
    </ModalForm>
  )
}

export default MyInventoryModal
import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { userLogin, getAllSpecialId } from '../../store/modules/user.js'
import { loginOut } from '../../store/modules/user.js'

import { getToken } from '../../utils'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  if (getToken('')) {
    dispatch(loginOut())
  }

  const onFinish = (values) => {
    if (!values.agreement) {
      message.error('请同意服务条款。');
      return;
    }

    const { username, password } = values;
    dispatch(userLogin({ username, password }))
      .then((res) => {
        if (res.status === 200) {
          message.success(res.data.message)
          dispatch(getAllSpecialId())
            .then(() => {
              navigate('/layout')
            })
        }
      })
      .catch((err) => {
        message.error(err.request.message)
      })
  }

  return (
    <div className='cloud-saves-login'>
      <div className='cloud-saves-login-mask'>
        <div className='cloud-saves-login-content'>
          <h1 className='cloud-saves-login-title'>云存档登录</h1>
          <Form
            name="basic"
            onFinish={onFinish}
            style={{ width: 300, margin: 'auto', marginTop: 26 }}
          >
            <Form.Item
              label="用户名"
              name="username"
              rules={[{ required: true, message: '请输入用户名!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="密　码"
              name="password"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="agreement" valuePropName="checked">
              <Checkbox>我已阅读并同意<a href="/terms">服务条款</a></Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                登录
              </Button>
              <Link to="/register">
                <Button type="link">注册</Button>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { message } from "antd";

function compareVersion(versions1, versions2, type) {
    // 将版本号字符串拆分成数组，以"."为分隔符
    var v1 = versions1.split('.');
    var v2 = versions2.split('.');
    
    // 循环遍历数组中的每个部分，依次比较大小
    for (var i = 0; i < Math.max(v1.length, v2.length); i++) {
        var num1 = parseInt(v1[i] || 0); // 如果v1[i]不存在，则默认为0
        var num2 = parseInt(v2[i] || 0); // 如果v2[i]不存在，则默认为0
        
        
        if (type) {
            if (num1 > num2) {
                return 1
            } else if (num1 < num2) {
                return -1
            }
        } else {
            if (num1 > num2) {
                return true;
            } else if (num1 < num2) {
                return message.warning('当前选择存档为旧版本');
            }
        }

    }
}


export {
    compareVersion
}
import React, { useEffect, useState } from 'react';
import { InboxOutlined, FileZipOutlined, CloseOutlined } from '@ant-design/icons';
import { message, Upload as AntUpload, Spin, Button, AutoComplete, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';

import { setFileInfo, setIsExist, setFolderId, setFileHash, setFileUploading, addSaveInfo, setFolderName, checkExist, setExInfo, updateSave, setFileId, uploadStatusReset, setUploadMode } from '../../store/modules/upload'
import { addUserFolderId } from '../../store/modules/user'

import DrawerComp from '../../components/Drawer';

import { request, saveNameMatch, calculateHash, getToken, compareVersion } from '../../utils';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es'

const { Dragger } = AntUpload;


const props = {
  name: 'file',
  multiple: false,
  showUploadList: false,
  accept: '.zip,.rar',
};

let submitState = false
let submitReadyState = false

let imgUrl = ''

const Upload = () => {
  const { userInfo } = useSelector(state => state.user)
  const { fileInfo } = useSelector(state => state.upload)
  const { isExist } = useSelector(state => state.upload)
  const { folderId } = useSelector(state => state.upload)
  const { fileId } = useSelector(state => state.upload)
  const { fileUploaded } = useSelector(state => state.upload)
  const { fileUploading } = useSelector(state => state.upload)
  const { formSaveInfo, fileHash } = useSelector(state => state.upload)
  const { folderName } = useSelector(state => state.upload)
  const { exInfo } = useSelector(state => state.upload)
  const { uploadMode } = useSelector(state => state.upload)
  const { search } = useSelector(state => state.upload)
  const { url } = useSelector(state => state.upload)

  const [localHashValue, setLocalHashValue] = useState('')
  const [cloudHashValue, setCloudHashValue] = useState('')
  const [fileSize, setFileSize] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    submitState = fileUploading
  }, [fileUploading])

  useEffect(() => {
    submitReadyState = fileUploaded
  }, [fileUploaded])

  const onChange = (info) => {
    const { status } = info.file
    if (status === 'done') {
      const size = fileSize
      const handleUploadSuccess = (fileName, res) => {
        if (res.response?.status === 400) {
          message.error(fileName + '文件上传失败');
        } else {
          message.success(fileName + ` 文件上传成功。`);
        }
        dispatch(setFileUploading(false));
        dispatch(uploadStatusReset());
        imgUrl = ''
      }

      const handleUploadError = (err) => {
        message.error('文件上传失败');
        dispatch(setFileUploading(false));
        dispatch(uploadStatusReset());
        imgUrl = ''
      }

      if (isExist) {
        const gameId = exInfo.id;
        dispatch(updateSave({ formSaveInfo, fileHash, folderId, fileId, size, uploadMode, gameId, imgUrl }))
          .then((res) => handleUploadSuccess(info.file.name, res))
          .catch((err) => handleUploadError(err))
      } else {
        dispatch(addSaveInfo({ formSaveInfo, fileHash, folderId, fileId, size, imgUrl }))
          .then((res) => handleUploadSuccess(info.file.name, res))
          .catch((err) => handleUploadError(err))
      }
    } else if (status === 'error') {
      message.error(info.file.name + `文件上传失败。`);
      dispatch(setFileUploading(false))
    }
  }

  const isExistHandler = (saveInfo) => {
    dispatch(checkExist(saveInfo))
      .then((res) => {
        if (res.data.saveInfo) {
          dispatch(setIsExist(true))
          dispatch(setExInfo(res.data.saveInfo))
          let versionExists = false;
          if (res.data.saveVersionInfo.length > 0) {
            res.data.saveVersionInfo.forEach(save => {
              if (save.versions === saveInfo.versions) {
                versionExists = true;
              }
            })
            if (versionExists) {
              message.error('选择的存档版本似乎与云端版本相同，请检查存档文件')
              setCloudHashValue(res.data.saveVersionInfo[0].hash_value)
            } else {
              compareVersion(saveInfo.versions, res.data.saveVersionInfo[0].versions)
            }
          } else {
            message.info('存档信息存在，但存档文件为空')
          }
        } else {
          dispatch(setIsExist(false))
        }
      })
      .catch((err) => {
        message.error('检查存档信息失败')
      })
  }

  const uploadFileBefore = (file) => {
    const { name, size, type } = file
    setFileSize(size)
    const temp = saveNameMatch(name)
    const saveInfo = {
      gameENName: temp.gameName,
      versions: temp.versions,
    }

    calculateHash(file)
      .then((hash) => {
        setLocalHashValue(hash)
        dispatch(setFileHash(hash))
      })
      .catch((err) => {
        message.error('文件hash值解析失败，请检查文件', err)
      })

    isExistHandler(saveInfo)

    dispatch(setFileInfo({ name, size, type, saveInfo }))
    dispatch(setFolderName(saveInfo.gameENName))

    return new Promise((resolve, reject) => {
      const checkSubmisStatus = setInterval(() => {
        if (submitState) {
          clearInterval(checkSubmisStatus)
          resolve(file)
        }
        if (!submitReadyState) {
          clearInterval(checkSubmisStatus)
          reject(new Error('用户已取消上传'))
        }
      }, 1000)
    })
  }

  const inputImgUrlHandler = (inputImgUrl) => {
    if (typeof inputImgUrl === "string" && inputImgUrl !== '') {
      imgUrl = inputImgUrl
    } else {
      if (!isExist) {
        imgUrl = 'https://s3.bmp.ovh/imgs/2024/05/19/ce314c38b3038176.png'
      } else {
        imgUrl = exInfo.img_url
      }
    }
  }

  const customRequest = async ({ file, onSuccess, onError }) => {
    if (localHashValue === cloudHashValue) {
      message.error('本地存档文件哈希值与云端文件一致，禁止上传')
      onError()
    } else {
      if (url) {
        const inputImgUrl = prompt('请填写图片链接')
        inputImgUrlHandler(inputImgUrl)
      } else {
        try {
          let regex = /patreon\.com|fandom\.com|subscribestar\.adult/
          if (regex.test(formSaveInfo.webUrl)) {
            const response = await axios.get(`https://patreon-tool.dmfy.fun/get-images-url?url=${formSaveInfo.webUrl}`)
            if (Object.keys(response.data).length === 0) {
              imgUrl = exInfo.img_url
            } else {
              imgUrl = response.data.imgUrl
            }
          } else if (!exInfo.img_url) {
            imgUrl = 'https://s3.bmp.ovh/imgs/2024/05/19/ce314c38b3038176.png'
          }
        }
        catch {
          const inputImgUrl = prompt('获取图片链接失败，请手动填写')
          inputImgUrlHandler(inputImgUrl)
        }
      }
      try {
        let userFolderId = '01EFPEQPKCMUWLJUOFHBG2NRQFTBUEPJR5'
        if (userInfo.userFolderId) {
          userFolderId = userInfo.userFolderId
        } else {
          const initUserFolderUrl = `https://graph.microsoft.com/v1.0/me/drive/items/${userFolderId}/children`
          const data1 = {
            'name': `${userInfo.username}_${userInfo.id}`,
            'folder': {},
            "@microsoft.graph.conflictBehavior": "rename"
          }
          const { data: { id } } = await axios.post(initUserFolderUrl, data1, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ` + getToken('azure_token'),
            },
          })
          userFolderId = id
          dispatch(addUserFolderId(id, userInfo.username))
        }

        const createFolderUrl = `https://graph.microsoft.com/v1.0/me/drive/items/${userFolderId}/children`
        const data = {
          'name': `${folderName}`,
          'folder': {},
          "@microsoft.graph.conflictBehavior": "rename"
        }
        let res = {}
        let requestFolderId = ''

        if (!isExist) {
          res = await axios.post(createFolderUrl, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ` + getToken('azure_token'),
            },
          })
          requestFolderId = res.data.id
          dispatch(setFolderId(requestFolderId))
        } else {
          dispatch(setFolderId(exInfo.folder_id))
        }
        let uploadFileUrl = ''
        if (uploadMode === 'replace') {
          uploadFileUrl = `https://graph.microsoft.com/v1.0/me/drive/items/${exInfo.folder_id ? exInfo.folder_id : requestFolderId}:/${file.name}:/content`
        } else {
          uploadFileUrl = `https://graph.microsoft.com/v1.0/me/drive/items/${exInfo.folder_id ? exInfo.folder_id : requestFolderId}:/${dayjs().valueOf() + file.name}:/content`

        }
        const formData = new FormData();
        formData.append('file', file);
        res = await axios.put(uploadFileUrl, formData, {
          headers: {
            'Content-Type': file.type,
            Authorization: `Bearer ` + getToken('azure_token'),
          },
        })
        if (res.data.id) {
          dispatch(setFileId(res.data.id))
        }

        const checkFileUrl = `https://graph.microsoft.com/v1.0/me/drive/items/${res.data.id}`
        res = await axios.get(checkFileUrl, {
          headers: {
            Authorization: `Bearer ` + getToken('azure_token'),
          },
        })

        if (res.data.id) {
          dispatch(setUploadMode(false))
          onSuccess() //上传成功回调
        }
      } catch (error) {
        onError() // 上传失败回调
      }
    }
  }

  const [options, setOptions] = useState([]);
  const handleSearch = async (value) => {
    if (value) {
      try {
        const response = await request.get(`/cloudsave/api/save-info/get-game-name?game_en_name=${value}`, {
          headers: {
            Authorization: `Bearer ` + getToken(),
          },
        });
        const gameEnNames = response.data.gameEnNames.map(game => ({
          value: game.game_en_name,
        }))
        setOptions(gameEnNames);
      } catch {
        message.error('获取游戏名称失败')
      }
    } else {
      setOptions([]);
    }
  }

  const debouncedHandleSearch = debounce(handleSearch, 500);

  const handleSelect = (value) => {
    const versions = fileInfo.saveInfo.versions
    isExistHandler({ gameENName: value, versions })
    setOptions([])
  };


  return (
    <div className='Upload'>
      <h1 style={{ textAlign: 'center', marginBottom: '30px', color: '#1677ff' }}>云存档</h1>
      <Dragger
        {...props}
        beforeUpload={uploadFileBefore}
        disabled={fileUploading || fileUploaded}
        onChange={onChange}
        customRequest={customRequest}
        maxCount={1}
      >
        {!fileUploaded && !fileUploading && (
          <div>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">点击或拖动上传文件</p>
            <p className="ant-upload-hint">
              仅支持单个文件上传，且限定为压缩格式。
            </p>
          </div>
        )}
        {(fileUploaded || fileUploading) && (
          <div>
            {!fileUploading && (
              <p className="ant-upload-drag-icon">
                <FileZipOutlined />
              </p>
            )}
            {fileUploading && (
              <Spin className='Spin' spinning={true} style={{ marginTop: 23, marginBottom: 23 }} size='large' />
            )}
            <p className="ant-upload-text">{fileUploading ? '稍息片刻' : fileInfo.name}</p>
            <p className="ant-upload-hint">
              {fileUploading ? '正在向OneDrive上传存档...' : '点击下方开始存档按钮，完成存档信息记录后上传。'}
            </p>
          </div>
        )}
      </Dragger>
      {fileUploaded && search && (
        <div style={{ margin: '0 auto' }}>
          <p style={{ display: 'inline-block', marginRight: '10px', marginBottom: '12px' }}>游戏名(英文):</p>
          <AutoComplete
            style={{ width: 200, margin: '0 auto', display: 'inline-block' }}
            onSearch={debouncedHandleSearch}
            onSelect={handleSelect}
            options={options}
            placeholder="Search game name"
          >
            <Input />
          </AutoComplete>
        </div>
      )}
      <DrawerComp />
      {fileUploaded && (
        <Button icon={<CloseOutlined />} onClick={() => {
          dispatch(uploadStatusReset())
        }} style={{ marginTop: 2 }}>
          取消
        </Button>
      )}
    </div>
  )
}

export default Upload;

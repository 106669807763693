import { createSlice } from "@reduxjs/toolkit"

const listStore = createSlice({
    name: 'list',
    initialState: {
        gameList: [
            {
                id: 0,
                game_en_name: '',
                game_cn_name: '',
                web_url: '',
                type: '',
                latest_versions: '',
                isfinish: 0,
                priority: 0,
                last_play_time: '',
                tags: [],
                tags_color: [''],
                game_description: '',
                folder_id: ''
            }
        ],
        gameSaveList: [
            {
                file_id: "",
                game_id: 0,
                hash_value: "",
                save_file_id: 0,
                submission_time: "",
                version_description: "",
                versions: "0",
            }
        ],
        index: 0,
        deletePattern: false
    },
    reducers: {
        setGameList(state, action) {
            state.gameList = action.payload
        },
        setGameSaveList(state, action) {
            state.gameSaveList = action.payload
        },
        setIndex(state, action) {
            state.index = action.payload
        },
        setDeletePattern(state, action) {
            state.deletePattern = action.payload
        }
    }
})

const { setIndex, setGameList, setGameSaveList, setDeletePattern } = listStore.actions

const reducer = listStore.reducer

export { setIndex, setGameList, setGameSaveList, setDeletePattern }

export default reducer
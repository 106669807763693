import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { refreshAzureToken } from '../../../store/modules/user';


const CallBack = () => {
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(3000 / 1000);
  const [firstToken, setFirstToken] = useState(false);
  const dispatch = useDispatch()
  const [params] = useSearchParams()

  const specialId = params.get('specialid')
  const isExist = params.get('isExist')

  useEffect(() => {
    if (specialId) {
      dispatch(refreshAzureToken(specialId, true))
      setFirstToken(true)
    }
  }, [dispatch, specialId, isExist])

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/layout')
    }, 3000);

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearTimeout(timer)
      clearInterval(interval)
    };
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <Spin size="large" />
      {firstToken && (
        <p>登录完成等待 {countdown} 秒后重定向至上传页...</p>
      )}
      <p>等待处理程序申请必要数据...</p>
    </div>
  );
};

export default CallBack;

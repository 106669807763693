import { createSlice } from "@reduxjs/toolkit"
import dayjs from 'dayjs';

import { request, getToken, setToken } from "../../utils"

const uploadStore = createSlice({
    name: 'upload',
    initialState: {
        fileInfo: {},
        folderName: '',
        folderId: '',
        fileId: '',
        isExist: false,
        exInfo: {},
        fileHash: '',
        formSaveInfo: {},
        fileUploaded: false,
        fileUploading: false,
        uploadMode: 'replace',
        edit: true,
        search: (() => {
            const searchValue = getToken('search');
            return searchValue ? searchValue === 'true' ? true : false : false;
        })(),
        url: (() => {
            const urlValue = getToken('url');
            return urlValue ? urlValue === 'true' ? true : false : false;
        })()
    },
    reducers: {
        setFileInfo: (state, action) => {
            state.fileInfo = action.payload
        },
        setFolderName: (state, action) => {
            state.folderName = action.payload
        },
        setFolderId: (state, action) => {
            state.folderId = action.payload
        },
        setFileId: (state, action) => {
            state.fileId = action.payload
        },
        setIsExist: (state, action) => {
            state.isExist = action.payload
        },
        setExInfo: (state, action) => {
            state.exInfo = action.payload
        },
        setFileHash: (state, action) => {
            state.fileHash = action.payload
        },
        setFormSaveInfo: (state, action) => {
            action.payload.lastPlayTime = dayjs(action.payload.lastPlayTime).format('YYYY-MM-DD')
            state.formSaveInfo = action.payload
        },
        setFileUploaded: (state, action) => {
            state.fileUploaded = action.payload
        },
        setFileUploading: (state, action) => {
            state.fileUploading = action.payload
        },
        mergeFormData: (state, action) => {
            state.formSaveInfo = { ...state.formSaveInfo, ...state.fileHash }
        },
        setFormTags: (state, action) => {
            state.formSaveInfo.tags = action.payload
        },
        setFormTagsColor: (state, action) => {
            state.formSaveInfo.tagsColor = action.payload
        },
        setUploadMode: (state, action) => {
            if (action.payload) {
                state.uploadMode = 'coexist'
            } else {
                state.uploadMode = 'replace'
            }
        },
        setEdit: (state, action) => {
            state.edit = action.payload
        },
        uploadStatusReset: (state, action) => {
            state.fileUploaded = false
            state.fileInfo = {}
            state.isExist = false
            state.exInfo = {}
            state.edit = true
        },
        setSearch: (state, action) => {
            state.search = action.payload
            setToken('search', action.payload)
        },
        setUrl: (state, action) => {
            state.url = action.payload
            setToken('url', action.payload)
        }
    }
})

//----------------------------------------------------------------------------------------------------------

//异步请求部分↓↓↓

//----------------------------------------------------------------------------------------------------------

const dataProcessing = async (data) => {
    if (typeof data.webUrl === 'undefined') {
        data.webUrl = ''
    }
    if (typeof data.tags === 'undefined') {
        delete data.tags
        delete data.tagsColor
    }
    if (typeof data.description === 'undefined') {
        data.description = ''
    }
    if (data.isFinish === 'yes') {
        data.isFinish = 1
    } else {
        data.isFinish = 0
    }
    data.priority = +data.priority
    return data
}

const addSaveInfo = ({ formSaveInfo, fileHash, folderId, fileId, size, imgUrl }) => {
    return async () => {
        let data = await dataProcessing({ ...formSaveInfo, fileHash, folderId, fileId, size, imgUrl })
        const formData = new URLSearchParams(data)
        const res = await request.post('/cloudsave/api/save-info/add-save-info', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken()
            }
        })
        return res
    }
}

const checkExist = (saveInfo) => {
    return async (dispatch) => {
        const formData = new URLSearchParams(saveInfo)
        const res = await request.post('/cloudsave/api/save-info/isexist', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken()
            }
        })
        dispatch(setFileUploaded(true))
        return res
    }
}

const updateSave = ({ formSaveInfo, fileHash, folderId, fileId, size, uploadMode, gameId, imgUrl }) => {
    return async () => {
        let data = await dataProcessing({ ...formSaveInfo, fileHash, folderId, fileId, size, uploadMode, gameId, imgUrl })
        const formData = new URLSearchParams(data)
        const res = await request.post('/cloudsave/api/save-info/update-save-file', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken()
            }
        })
        return res
    }
}

const { setFileInfo, setIsExist, setFolderId, setFileHash, setFormSaveInfo, setFileUploaded, setFileUploading, setFormTags, setFolderName, setExInfo, setFileId, setFormTagsColor, setUploadMode, setEdit, uploadStatusReset, setSearch, setUrl } = uploadStore.actions

const reducer = uploadStore.reducer

export { setFileInfo, setIsExist, setFolderId, setFileHash, setFormSaveInfo, setFileUploaded, setFileUploading, addSaveInfo, setFormTags, setFolderName, checkExist, setExInfo, updateSave, setFileId, setFormTagsColor, setUploadMode, setEdit, uploadStatusReset, setSearch, setUrl }

export default reducer
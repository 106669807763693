import axios from "axios"
import { message } from 'antd';

const request = axios.create({
    baseURL: 'https://api.cloud-saves.dmfy.fun',
    timeout: 5000
})

//请求拦截器
//触发该函数的状态码范围：2xx
request.interceptors.request.use(
    config => {
        return config
    }, (error) => {
        return error
    })

//响应拦截器
//触发该函数的状态码范围：2xx以外
request.interceptors.response.use(
    response => {
        return response
    }, (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                window.location.href = '/login'
            }
        }
        if (error.response?.data.message) {
            message.error(error.response.data.message)
        }
        return error
    })

export { request }
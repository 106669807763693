import React from 'react';
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import router from './router'
import store from './store';
import { Provider } from 'react-redux';
import 'normalize.css'
import './index.css'
import { App, message } from 'antd'

message.config({
  maxCount: 3
})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <App>
      <Provider store={store}>
        <RouterProvider router={router}></RouterProvider>
      </Provider>
    </App>
);

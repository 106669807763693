import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Space, Input, Button, Switch, message, Form } from 'antd';

import { setDeletePattern } from '../../../store/modules/list'
import { setSearch, setUrl } from '../../../store/modules/upload'

import { request } from '../../../utils/index'
import { getToken } from '../../../utils';

import { loginOut } from '../../../store/modules/user';


const AccountDetails = () => {
    const { userInfo } = useSelector(state => state.user)
    const { deletePattern } = useSelector(state => state.list)
    const { search } = useSelector(state => state.upload)
    const { url } = useSelector(state => state.upload)
    const [password, setPassword] = useState('')
    const [isFormOpen, setIsFormOpen] = useState('')
    const dispatch = useDispatch()

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [admin, setAdmin] = useState(false)

    const onFinish = async (values) => {
        const formData = new URLSearchParams(values)
        try {
            const res = await request.put(`/cloudsave/api/user/${admin ? 'admin/update-password' : 'update-password'}`, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ` + getToken()
                }
            })
            setLoading(false);
            if (res.status === 200) {
                message.success(res.data.message)
                form.resetFields()
                if (admin) {
                    setAdmin(false)
                } else {
                    setIsFormOpen(false)
                    setTimeout(() => {
                        dispatch(loginOut())
                    }, 1000);
                }
            }
        }
        catch (err) {
            setLoading(false);
            if (err.request?.response) {
                message.error(JSON.parse(err.request.response).message)
            }
        }
    };

    const onFinishFailed = (err) => {
        message.error('密码修改失败！')
    };

    const validateConfirmPassword = (_, value) => {
        const { newPassword } = form.getFieldsValue();
        if (value && value !== newPassword) {
            return Promise.reject('两次输入的密码不一致！');
        }
        return Promise.resolve();
    };

    const submitHandler = () => {
        const formData = new URLSearchParams({ password });
        request.post('/cloudsave/api/user/verify-password', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ` + getToken()
            }
        })
            .then(res => {
                setPassword('')
                if (res.status === 200) {
                    message.success(res.data.message)
                    dispatch(setDeletePattern(true))
                } else {
                    message.error(res.data.message)
                    dispatch(setDeletePattern(false))
                }
            })
            .catch(err => {
                if (err.request?.response) {
                    message.error(JSON.parse(err.request.response).message)
                }
            })
    }

    return (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <h1>账户明细</h1>
            <p>用户名：{userInfo.username}</p>
            <p>邮箱：{userInfo.email}</p>
            {!isFormOpen && !admin && (
                <Button style={{ margin: '0px 5px 0px 5px' }} onClick={() => setIsFormOpen(true)}>修改密码</Button>
            )}
            {userInfo.admin === 1 && !isFormOpen && !admin && (
                <Button style={{ margin: '0px 5px 0px 5px' }} onClick={() => setAdmin(true)}>修改用户密码</Button>
            )}
            {isFormOpen && (
                <Form
                    form={form}
                    name="changePasswordForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    style={{ maxWidth: 500, margin: 'auto' }}
                >
                    <Form.Item
                        label="当前密码"
                        name="oldPassword"
                        rules={[{ required: true, message: '请输入当前密码!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[
                            { required: true, message: '请输入密码!' },
                            { min: 6, max: 30, message: '密码长度必须为6到30位!' },
                            { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/, message: '密码必须包含大小写字母、数字和特殊字符!' },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="确认新密码"
                        name="confirmNewPassword"
                        dependencies={['newPassword']}
                        rules={[
                            { required: true, message: '请输入密码!' },
                            { min: 6, max: 30, message: '密码长度必须为6到30位!' },
                            { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/, message: '密码必须包含大小写字母、数字和特殊字符!' },
                            { validator: validateConfirmPassword },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 5, span: 14 }}>
                        <Button type="primary" style={{ margin: '0px 5px 0px 5px' }} htmlType="submit" loading={loading}>
                            确定
                        </Button>
                        <Button htmlType="cancel" style={{ margin: '0px 5px 0px 5px' }} loading={loading} onClick={() => { setIsFormOpen(false) }}>
                            取消
                        </Button>
                    </Form.Item>
                </Form>
            )}
            {admin && (
                <Form
                    form={form}
                    name="adminChangeUserPasswordForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    style={{ maxWidth: 500, margin: 'auto' }}
                >
                    <Form.Item
                        label="管理员密码"
                        name="adminPassword"
                        rules={[{ required: true, message: '请输入管理员密码!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="用户id"
                        name="userId"
                        rules={[
                            { required: true, message: '请输入用户id!' },
                            { pattern: /^\d+$/, message: '用户id必须为数字!' }]
                        }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="用户新密码"
                        name="newPassword"
                        rules={[
                            { required: true, message: '请输入密码!' },
                            { min: 6, max: 30, message: '密码长度必须为6到30位!' },
                            { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/, message: '密码必须包含大小写字母、数字和特殊字符!' },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 5, span: 14 }}>
                        <Button type="primary" style={{ margin: '0px 5px 0px 5px' }} htmlType="submit" loading={loading}>
                            确定
                        </Button>
                        <Button htmlType="cancel" style={{ margin: '0px 5px 0px 5px' }} loading={loading} onClick={() => { setAdmin(false) }}>
                            取消
                        </Button>
                    </Form.Item>
                </Form>
            )}
            <div>
                <p>上传时搜索功能</p>
                <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={search} onChange={(checked) => { dispatch(setSearch(checked)) }} />
            </div>
            <div>
                <p>上传时输入图片url功能</p>
                <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={url} onChange={(checked) => { dispatch(setUrl(checked)) }} />
            </div>
            <p style={{ color: 'red' }}>{deletePattern ? '点击下方按钮关闭存档库删除功能' : '请输入密码，完成验证以开启存档库删除功能'}</p>
            {!deletePattern && (
                <Space.Compact>
                    <Input.Password
                        placeholder='请输入密码'
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />
                    <Button type="primary" onClick={submitHandler}>验证</Button>
                </Space.Compact>
            )}
            {deletePattern && (
                <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked onChange={(checked) => { dispatch(setDeletePattern(checked)) }} />
            )}
        </div>
    );
};

export default AccountDetails

import { createBrowserRouter } from 'react-router-dom'
import Layout from '../pages/Layout';
import Index from '../pages/Index';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Upload from '../pages/Upload';
import CallBack from '../pages/Onedrive/CallBack';
import Inventory from '../pages/Layout/Inventory/index.jsx';
import AccountDetails from '../pages/Layout/AccountDetails/index.jsx';
import Terms from '../pages/Terms';

import { AuthRoute } from '../components/AuthRoute.jsx'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
  },
  {
    path: '/layout',
    element: <AuthRoute><Layout /></AuthRoute>,
    children: [
      {
        index: true,
        element: <Upload />,
      },
      {
        path: 'inventory',
        element: <Inventory />,
      },
      {
        path: 'Account-details',
        element: <AccountDetails />,
      }
    ]
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'register',
    element: <Register />,
  },
  {
    path: '/terms',
    element: <Terms />,

  },
  {
    path: '/onedrive/callback',
    element: <AuthRoute><CallBack /></AuthRoute>,
  }
])
export default router;

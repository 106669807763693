import React from 'react';
import { Modal, Button } from 'antd';

class MyModal extends React.Component {
  render() {
    return (
      <Modal
        title={this.props.title}
        open={this.props.isModalOpen}
        onOk={this.props.onOk}
        onCancel={this.props.onCancel}
        closable={this.props.closable}
        maskClosable={this.props.maskClosable}
        keyboard={this.props.keyboard}
        centered={this.props.centered}
        footer={[
          <Button key="submit" type="primary" onClick={this.props.onOk}>
            确定
          </Button>,
        ]}
      >
        <p>{this.props.content}</p>
      </Modal>
    );
  }
}

export default MyModal;
